








import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import {
  CHART_CLICK_EVENT,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_STATE_CHANGE_TYPE,
} from '@/lib/constants';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import { Debounce } from 'vue-debounce-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { DateTime } from 'luxon';

import {
  getConfigEnv, getComponent, getView, sleep,
} from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import {
  lightningChart,
  OnScreenMenuButtonType,
  OnScreenMenuButtonShape,
  UIElementBuilders,
  UIOrigins,
  ImageFill,
  ImageFitMode,
  Themes,
  ColorRGBA,
  ColorHEX,
  SolidFill,
  PointShape,
  SolidLine,
  AxisTickStrategies,
  LegendBoxBuilders,
  AutoCursorModes,
  LegendBox,
  MouseClickEventType,
  MouseEventHandler,
  UILUTCheckBox,
  emptyLine,
  emptyFill,
  ColorCSS,
  customTheme,
  UIVisibilityModes,
  AxisScrollStrategies,
} from '@arction/lcjs';
// Extract required parts from XYData Generator.
import {
  createProgressiveTraceGenerator,
  createOHLCGenerator,
  createProgressiveRandomGenerator,
} from '@arction/xydata';

@Component({
  components: {
    VueSlider,
  },
})
export default class TasqLightningProductionChart extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) isFullScreen?: boolean;

  chart:any = null

  chartId:any = null

  chartsLoading = false

  localWellHistories: any = []

  axisY;

  axisY2;

  minY = 0;

  maxY;

  dateOrigin;

  chartSeries:any = []

	points = [
	]

	reloadChartOnScroll() {
        this.chart.engine.layout()
    }


	created() {
	  //  this.fetchTasq()
	  // $((window as any)).on('resize', function(){
	  // 	VueEcharts.resize()
	  //     // if(chart != null && chart != undefined){
	  //     //     chart.resize();
	  //     // }
	  // });

	    this.$eventBus.$on('history-search-updated', (history) => {
	    for (let x = 0; x < this.chartHistoryButtons.length; x++) {
	      if (this.chartHistoryButtons[x]) {
	        this.chartHistoryButtons[x].dispose();
	      }
	    }
		
	    const filteredHistories = (history.filter((h) => h.hideEvent !== true));
	    this.localHistoryItems = (filteredHistories);

	    if (filteredHistories && filteredHistories.length) {
	    //   tasqProductionDataChartModule.setShowWellEvents(true);
	      this.addHistoryToChart(true);
	    } else {
	    //   tasqProductionDataChartModule.setShowWellEvents(false);
		  this.addHistoryToChart(false);
	    }

	      // console.log(result);
	    });
	}

	get isHistorySectionOpen() {
    return tasqsListModule.isHistorySectionOpen
  }

	didSelectResetChart() {
	  this.chart.getDefaultAxisX().release();
	  this.chart.getDefaultAxisY().release();
	  this.axisY.release();
	  this.axisY2.release();
	  if (this.maxY != undefined && this.maxY != -99999999) {
			this.axisY.setInterval(this.minY, this.maxY, false, true)
		}
	}

	async initializePage(index, signal) {
	  if (this.chartSeries[index].isDisposed()) {
	    this.chartSeries[index].restore();
	  } else {
	    this.chartSeries[index].dispose();
	  }
	}

	get getWellHistoryEvents() {
	  return workflowModule.wellHistoryEvents;
	}

	chartHistoryButtons:any = []

	async addHistoryToChart(showEvent) {
	  if (showEvent) {
	    const axisX = this.chart.getDefaultAxisX();
	    for (var x = 0; x < this.localHistoryItems.length; x++) {
	      if (this.localHistoryItems.hideEvent !== true) {
	      const timeObj = new Date(this.localHistoryItems[x].time);
	      const diff = timeObj.getTime() - this.dateOrigin.getTime() - 172800000;
	      const diff_in_min = (diff / 60000);

	      const fromTime = new Date();
	      const toTime = new Date();
	      toTime.setDate(toTime.getDate() - 1);
 			fromTime.setDate(fromTime.getDate() - tasqsListModule.signalChartTime);

	      // const fromTime = DateTime.utc().minus({ days: 365 })
	      if (fromTime > timeObj) {
	        continue;
	      }

		  const yInterval = this.chart.getDefaultAxisY().getInterval()
	      const buttonReset = this.chart
	        .addUIElement(UIElementBuilders.TextBox, { x: axisX, y: this.axisY })
	        .setText('E')
	        .setPosition({ x: (diff_in_min * (1 * 1000 * 60)), y: yInterval.end - 50 })
	        .setOrigin(UIOrigins.LeftBottom)
	        .setMargin({ left: 4, bottom: 4 });
	      buttonReset.historyDetails = this.localHistoryItems[x];
	      buttonReset.onMouseClick((obj, event) => {
	        this.$emit('show-event-comment-popup', obj.historyDetails);
	        // showEventCommentPopup(obj)
	      });


	      this.chartHistoryButtons.push(buttonReset);
	    }
	    }
	  } else {
	    for (var x = 0; x < this.chartHistoryButtons.length; x++) {
	      this.chartHistoryButtons[x].dispose();
	    }
	  }
	}

	localHistoryItems: any = []

	get showWellEvents() {
	  return tasqProductionDataChartModule.showWellEvents;
	}

	get isEditing() {
	  return tasqsListModule.isEditing;
	}

	get activeTasq() {
	  if (this.isEditing || tasqsListModule.checkedTasqs.length) {
		  if (!tasqsListModule.activeTasq) {
	      tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
	    }
		  return tasqsListModule.activeTasq as TasqJob;
	  }
	  if (this.$route.query.type == 'id' && this.$route.params.id != null) {
	    assetsModule.setActiveProducingTasq('');
	    return tasqsListModule.activeTasq;
	  }
	  if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level.toLowerCase() == 'pad') {
	  return tasqsListModule.activeTasq;
	  }
	  if (assetsModule.activeTasq == undefined) {
	    return tasqsListModule.activeTasq;
	  }
	  return assetsModule.activeTasq;
	}

	get tasqListLevel() {
	  if (tasqsListModule.tasqListLevel.toLowerCase() == 'well' || (this.activeTasq && tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' && this.activeTasq.level.toLowerCase() == 'well')) {
		  return 'Well';
	  }
	  return tasqsListModule.tasqListLevel.toLowerCase();
	  //   if (this.activeTasq == null) {
	  // 	  return 'WELL';
	  //   }
	  //   return this.activeTasq?.level;
	}

	get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
	}

	get currentSignals(): any {
	  // if (this.chartsLoading) {
	  //   return [];
	  // }
	  const results: any[] = [];
	  for (let x = 0; x < this.signalDescriptions.length; x++) {
	    // @ts-ignore
	    if (this.tasqListLevel.toLowerCase() == 'pad') {
	      if (!(this.signalDescriptions[x].indexOf('Compressor') !== -1)) {
	        continue;
	      }
	    }
	    for (let y = 0; y < tasqSignalsModule.currentSignals.length; y++) {
	      if (tasqSignalsModule.currentSignals[y].name == this.signalDescriptions[x]) {
	        results.push(tasqSignalsModule.currentSignals[y]);
	      }
	    }
	  }
	  // signalDescriptions
	  return results;
	}

	storedLinesSeries:any = []

	setViewNicely = (areaRange, stockAxisY) => {
	  const yBoundsStock = { min: areaRange.getYMin(), max: areaRange.getYMax(), range: areaRange.getYMax() - areaRange.getYMin() };
	  // Set Y axis intervals so that series don't overlap and volume is under stocks.
	  stockAxisY.setInterval(yBoundsStock.min - yBoundsStock.range * 0.33, yBoundsStock.max);
	}

	setupForOilTargets() {
	  this.axisY.setTitle('Oil Rate & Water Rate');
	  this.axisY2.setTitle('Gas Rate');
	  // var max_interval_one = 0
	  // var max_interval_two = 0
	  const prodDataFrequency = 1000 * 60 * 60 * 24;
	  for (const [key, value] of Object.entries(tasqProductionDataChartModule.productionDataDict)) {
	    if (key == 'date' || value == null || value == undefined || key == 'nodeid') {
	      continue;
	    }

	    var day_count = 0;
	    var check_date = new Date(this.dateOrigin.getTime());
	    const signal_data:any = [];
	    while (check_date < new Date(tasqProductionDataChartModule.productionDataDict.date[0])) {
	      // @ts-ignore
	      signal_data.push({
	        // @ts-ignore
	        x: day_count * 24 * 60 * 60 * 1000,
	        // @ts-ignore
	        y: 0,
	      });

	      check_date.setDate(check_date.getDate() + 1);
	      day_count += 1;
	    }

		if (key == 'oil_rate') {
			this.maxY = -99999999
			this.minY = 0
		}

	    for (let signal_y = 0; signal_y < tasqProductionDataChartModule.productionDataDict[key].length; signal_y++) {
	      let val = parseFloat(tasqProductionDataChartModule.productionDataDict[key][signal_y]);
	      if (isNaN(val)) {
	        val = 0;
	      }



		  if (key == 'oil_rate' || key == "water_rate" ) {
			if (val > this.maxY) {
				this.maxY = val
			}
			if (val < this.minY) {
				this.minY = val
			}
		  }


	      // @ts-ignore
	      signal_data.push({
	        // @ts-ignore
	        x: (signal_y + day_count) * prodDataFrequency,
	        // @ts-ignore
	        y: val,
	      });
	    }
	    if (key == 'oil_rate' || key == 'water_rate') {
			signal_data.push({
			 // @ts-ignore
			 x: (tasqsListModule.signalChartTime)*(1000*60*5*12*24),
	        // @ts-ignore
	        y: undefined,

		})
	      // Add line series to the chart
	      const lineSeriesSignals = this.chart.addLineSeries({
	        yAxis: this.axisY,
	      });

	      if (key == 'water_rate') {
	        lineSeriesSignals.setStrokeStyle(new SolidLine({
	          thickness: 2,
	          fillStyle: new SolidFill({ color: ColorRGBA(0, 118, 255) }),
	        }));
	      } else {
	        lineSeriesSignals.setStrokeStyle(new SolidLine({
	          thickness: 2,
	          fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193) }),
	        }));
	      }
	      lineSeriesSignals.setName(key.replace('_', ' '));
	      // Add data points to the line series
	      lineSeriesSignals.add(signal_data);
	      // legendProd.add(lineSeriesSignals)
	      this.chartSeries.push(lineSeriesSignals);
	    } else {
	      // Add line series to the chart
	      const lineSeriesSignals = this.chart.addLineSeries({
	        yAxis: this.axisY2,
	      });

	      // Set stroke style of the line
	      lineSeriesSignals.setStrokeStyle((style) => style.setThickness(2));
	      lineSeriesSignals.setStrokeStyle(new SolidLine({
	        thickness: 2,
	        fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139) }),
	      }));
	      lineSeriesSignals.setName(key.replace('_', ' '));
	      // Add data points to the line series
	      lineSeriesSignals.add(signal_data);
	      // legendProd.add(lineSeriesSignals)

	      this.chartSeries.push(lineSeriesSignals);
	    }
	  }

	  // Add series.
	  const areaRange = this.chart.addAreaRangeSeries({ yAxis: this.axisY })
	    .setName('Target band');

	  var day_count = 0;
	  var check_date = new Date(this.dateOrigin.getTime());
	  var new_signal_data:any = [];

	  if (tasqProductionDataChartModule.productionData.time && tasqProductionDataChartModule.productionData.time[0]) {
	    while (check_date < new Date(tasqProductionDataChartModule.productionData.time[0])) {
	      areaRange.add({
	        position: (day_count) * 24 * 60 * 60 * 1000,
	        high: 0,
	        low: 0,
	      });

	      // @ts-ignore
	      new_signal_data.push({
	        // @ts-ignore
	        x: day_count * 24 * 60 * 60 * 1000,
	        // @ts-ignore
	        y: 0,
	      });

	      check_date.setDate(check_date.getDate() + 1);
	      day_count += 1;
	    }
	  }

	  for (var d = 0; d < tasqProductionDataChartModule.productionData.data[6].length; d++) {

		if (parseFloat(tasqProductionDataChartModule.productionData.data[6][d]) > this.maxY) {
			this.maxY = parseFloat(tasqProductionDataChartModule.productionData.data[6][d])
		}

	    areaRange.add({
	      position: (d + day_count) * 24 * 60 * 60 * 1000,
	      high: parseFloat(tasqProductionDataChartModule.productionData.data[6][d]),
	      low: parseFloat(tasqProductionDataChartModule.productionData.data[8][d]),
	    });
	  }
	  this.setViewNicely(areaRange, this.axisY);

	  var new_signal_data:any = [];
	  for (var d = 0; d < tasqProductionDataChartModule.productionData.data[7].length; d++) {
	    // @ts-ignore
	    new_signal_data.push({
	      // @ts-ignore
	      x: (d + day_count) * prodDataFrequency,
	      // @ts-ignore
	      y: parseFloat(tasqProductionDataChartModule.productionData.data[7][d]),
	    });
	    // Add line series to the chart
	  }
	  const lineSeriesSignalsProd = this.chart.addLineSeries({
	    yAxis: this.axisY,
	  });

	  lineSeriesSignalsProd.setStrokeStyle(new SolidLine({
	    thickness: 2,
	    fillStyle: new SolidFill({ color: ColorRGBA(170, 170, 170, 100) }),
	  }));

	  // Set stroke style of the line
	  lineSeriesSignalsProd.setStrokeStyle((style) => style.setThickness(2));
	  lineSeriesSignalsProd.setName('Target');
	  // Add data points to the line series
	  lineSeriesSignalsProd.add(new_signal_data);

	  // legendProd.add(lineSeriesSignalsProd)
	  areaRange.setLowStrokeStyle(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193, 80) }) }));
	  areaRange.setLowStrokeStyleHighlight(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193, 80) }) }));
	  areaRange.setHighStrokeStyle(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193, 80) }) }));
	  areaRange.setHighStrokeStyleHighlight(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193, 80) }) }));
	  areaRange.setHighStrokeStyle(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193, 80) }) }));
	  areaRange.setHighStrokeStyleHighlight(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193, 80) }) }));

	  areaRange.setHighFillStyle(new SolidFill({
	    color: ColorRGBA(45, 230, 193, 80),
	  }));
	  areaRange.setLowFillStyle(new SolidFill({
	    color: ColorRGBA(45, 230, 193, 80),
	  }));

	  if (this.showWellEvents) {
	    this.addHistoryToChart(true);
	  }

	  // axisY2.fit()
	  // axisY.fit()
	  // const axisX = this.chart.getDefaultAxisX()

	  // for (var x = 0; x < this.getWellHistory.length; x++) {
	  // 	var timeObj = new Date(this.getWellHistory[x].time)
	  // 	var diff = timeObj.getTime() - this.dateOrigin.getTime();
	  // 	var diff_in_min = (diff / 60000);

	  // 	const buttonReset = this.chart
	  // 	.addUIElement(UIElementBuilders.TextBox, { x: axisX, y: this.axisY })
	  // 	.setText("E")
	  // 	.setPosition({ x: (diff_in_min * (1 * 1000 * 60)), y: 0 })
	  // 	.setOrigin(UIOrigins.LeftBottom)
	  // 	.setMargin({ left: 4, bottom: 4 })
	  // 	buttonReset.historyDetails = this.getWellHistory[x]
	  // 	buttonReset.onMouseClick((obj, event) => {
	  // 		this.$emit('show-event-comment-popup', obj.historyDetails);
	  // 		// showEventCommentPopup(obj)
	  // 	});
	  // }
	}

	setupForGasTargets() {
	  this.axisY.setTitle('Gas Rate');
	  this.axisY2.setTitle('Oil Rate & Water Rate');
	  const prodDataFrequency = 1000 * 60 * 60 * 24;
	  for (const [key, value] of Object.entries(tasqProductionDataChartModule.productionDataDict)) {
	    if (key == 'date' || value == null || value == undefined || key == 'nodeid') {
	      continue;
	    }

	    var day_count = 0;
	    var check_date = new Date(this.dateOrigin.getTime());
	    const signal_data:any = [];
	    while (check_date < new Date(tasqProductionDataChartModule.productionDataDict.date[0])) {
	      // @ts-ignore
	      signal_data.push({
	        // @ts-ignore
	        x: day_count * 24 * 60 * 60 * 1000,
	        // @ts-ignore
	        y: 0,
	      });

	      check_date.setDate(check_date.getDate() + 1);
	      day_count += 1;
	    }


		if (key == 'gas_rate') {
			this.maxY = -99999999
			this.minY = 0
		}

	    for (let signal_y = 0; signal_y < tasqProductionDataChartModule.productionDataDict[key].length; signal_y++) {
	      let val = parseFloat(tasqProductionDataChartModule.productionDataDict[key][signal_y]);
	      if (isNaN(val)) {
	        val = Number.NaN;
	      }
	      if (key == 'gas_rate') {
	        if (isNaN(val)) {
	          val = 0;
	        }
	      }
		  
		  if (key == 'gas_rate') {
			if (val > this.maxY) {
				this.maxY = val
			}
			if (val < this.minY) {
				this.minY = val
			}
		  }

	      // @ts-ignore
	      signal_data.push({
	        // @ts-ignore
	        x: (signal_y + day_count) * prodDataFrequency,
	        // @ts-ignore
	        y: val,
	      });

	    }
	    if (key == 'oil_rate' || key == 'water_rate') {
	      // Add line series to the chart
	      const lineSeriesSignals = this.chart.addLineSeries({
	        yAxis: this.axisY2,
	      });

	      if (key == 'water_rate') {
	        lineSeriesSignals.setStrokeStyle(new SolidLine({
	          thickness: 2,
	          fillStyle: new SolidFill({ color: ColorRGBA(0, 118, 255) }),
	        }));
	      } else {
	        lineSeriesSignals.setStrokeStyle(new SolidLine({
	          thickness: 2,
	          fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193) }),
	        }));
	      }
	      lineSeriesSignals.setName(key.replace('_', ' '));
	      // Add data points to the line series
	      lineSeriesSignals.add(signal_data);
	      // legendProd.add(lineSeriesSignals)

	      this.chartSeries.push(lineSeriesSignals);
	    } else {
	      // Add line series to the chart
	      const lineSeriesSignals = this.chart.addLineSeries({
	        yAxis: this.axisY,
	      });

	      // Set stroke style of the line
	      lineSeriesSignals.setStrokeStyle((style) => style.setThickness(2));
	      lineSeriesSignals.setStrokeStyle(new SolidLine({
	        thickness: 2,
	        fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139) }),
	      }));
	      lineSeriesSignals.setName(key.replace('_', ' '));
	      // Add data points to the line series
	      lineSeriesSignals.add(signal_data);
	      // legendProd.add(lineSeriesSignals)

	      this.chartSeries.push(lineSeriesSignals);


			this.axisY.setInterval(this.minY, this.maxY, false, true)



	    }
	  }

	  // Add series.
	  const areaRange = this.chart.addAreaRangeSeries({ yAxis: this.axisY })
	    .setName('Target band');

	  // var diff = timeObj.getTime() - this.dateOrigin.getTime();
	  // var diff_in_min = (diff / 60000);

	  // var fromTime = new Date();
	  // var toTime = new Date();
	  // toTime.setDate(toTime.getDate()-1);
 			// fromTime.setDate(fromTime.getDate()-365);

	  var day_count = 0;
	  var check_date = new Date(this.dateOrigin.getTime());
	  const new_signal_data:any = [];

	  while (check_date < new Date(tasqProductionDataChartModule.productionData.time[0])) {
	    areaRange.add({
	      position: day_count * 24 * 60 * 60 * 1000,
	      high: 0,
	      low: 0,
	    });

	    // @ts-ignore
	    new_signal_data.push({
	      // @ts-ignore
	      x: day_count * 24 * 60 * 60 * 1000,
	      // @ts-ignore
	      y: 0,
	    });

	    check_date.setDate(check_date.getDate() + 1);
	    day_count += 1;
	  }

	  // if (new Date(tasqProductionDataChartModule.productionData.time[0]) != this.dateOrigin) {
	  // 	 this.dateOrigin
	  // 	areaRange.add({
	  // 		position: d * 24 * 60 * 60 * 1000,
	  // 		high: parseFloat(tasqProductionDataChartModule.productionData.data[3][d]),
	  // 		low: parseFloat(tasqProductionDataChartModule.productionData.data[5][d]),
	  // 	})
	  // 	day_count += 1
	  // }

	  for (var d = 0; d < tasqProductionDataChartModule.productionData.data[3].length; d++) {

		if (parseFloat(tasqProductionDataChartModule.productionData.data[3][d]) > this.maxY) {
			this.maxY = parseFloat(tasqProductionDataChartModule.productionData.data[3][d])
		}

	    areaRange.add({
	      position: (d + day_count) * 24 * 60 * 60 * 1000,
	      high: parseFloat(tasqProductionDataChartModule.productionData.data[3][d]),
	      low: parseFloat(tasqProductionDataChartModule.productionData.data[5][d]),
	    });
	  }
	  this.setViewNicely(areaRange, this.axisY);

	  for (var d = 0; d < tasqProductionDataChartModule.productionData.data[4].length; d++) {
	    // @ts-ignore
	    new_signal_data.push({
	      // @ts-ignore
	      x: (d + day_count) * prodDataFrequency,
	      // @ts-ignore
	      y: parseFloat(tasqProductionDataChartModule.productionData.data[4][d]),
	    });
	    // Add line series to the chart
	  }
	  const lineSeriesSignalsProd = this.chart.addLineSeries({
	    yAxis: this.axisY,
	  });

	  lineSeriesSignalsProd.setStrokeStyle(new SolidLine({
	    thickness: 2,
	    fillStyle: new SolidFill({ color: ColorRGBA(170, 170, 170, 100) }),
	  }));

	  // Set stroke style of the line
	  lineSeriesSignalsProd.setStrokeStyle((style) => style.setThickness(2));
	  lineSeriesSignalsProd.setName('Target');
	  // Add data points to the line series
	  lineSeriesSignalsProd.add(new_signal_data);
	  // legendProd.add(lineSeriesSignalsProd)
	  areaRange.setLowStrokeStyle(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139, 80) }) }));
	  areaRange.setLowStrokeStyleHighlight(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139, 80) }) }));
	  areaRange.setHighStrokeStyle(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139, 80) }) }));
	  areaRange.setHighStrokeStyleHighlight(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139, 80) }) }));
	  areaRange.setHighStrokeStyle(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139, 80) }) }));
	  areaRange.setHighStrokeStyleHighlight(new SolidLine({ thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139, 80) }) }));

	  areaRange.setHighFillStyle(new SolidFill({
	    color: ColorRGBA(245, 93, 139, 80),
	  }));
	  areaRange.setLowFillStyle(new SolidFill({
	    color: ColorRGBA(245, 93, 139, 80),
	  }));

	  if (this.showWellEvents) {
	    this.addHistoryToChart(true);
	  }
	}

	createChart() {
	  const themeTextFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
	  // const themeDataSeriesFillStyles = [new SolidFill({ color: ColorCSS('#00FFEA') }),
	  // 								new SolidFill({ color: ColorCSS('#F21688') }),
	  // 								new SolidFill({ color: ColorCSS('#FFFF5D') }),
	  // 								new SolidFill({ color: ColorCSS('#FFCD5C') }),
	  // 								new SolidFill({ color: ColorCSS('#FFC8A5') }),
	  // 								new SolidFill({ color: ColorCSS('#FF94B8') }),
	  // 								new SolidFill({ color: ColorCSS('#DB94C6') }),
	  // 								new SolidFill({ color: ColorCSS('#A994C6') }),
	  // 								new SolidFill({ color: ColorCSS('#94B0C6') }),
	  // 								new SolidFill({ color: ColorCSS('#94E2C6') }),
	  // 								new SolidFill({ color: ColorCSS('#94FFB0') })]
	  const themeAxisFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
	  const themeMajorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
	  const themeMinorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
	  const themeMajorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000032') });
	  const themeMinorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000014') });
	  const themeUiBackgroundFillStyle = new SolidFill({ color: ColorCSS('rgba(120,120,120,0.5)') }); // Legend color
	  const themeUiBackgroundBorderFillStyle = new SolidFill({ color: ColorCSS('#2b2b2b') });
	  const themeCursorGridlineFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
	  // darkMagenta
	  const myTheme = customTheme(Themes.cyberSpace, {
	    lcjsBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
	    panelBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
	    seriesBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
	    chartTitleFillStyle: themeTextFillStyle,
	    axisTitleFillStyle: themeTextFillStyle,
	    axisStyle: new SolidLine({ thickness: 2, fillStyle: themeAxisFillStyle }),
	    numericTickStrategy: Themes.lightNew.numericTickStrategy
	      .setMajorTickStyle((majorTicks) => majorTicks
	          .setLabelFillStyle(themeTextFillStyle)
	          .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorTickFillStyle }))
	          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorGridlineFillStyle })))
	      .setMinorTickStyle((minorTicks) => minorTicks
	        // @ts-ignore
	          .setLabelFillStyle(themeTextFillStyle)
	          .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorTickFillStyle }))
	          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorGridlineFillStyle }))),
	    // seriesFillStyle: (i) => themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
	    // seriesStrokeStyle: (i) => new SolidLine({ thickness: 2, fillStyle: themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length] }),
	    uiBackgroundFillStyle: themeUiBackgroundFillStyle,
	    uiBackgroundStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
	    uiTextFillStyle: themeTextFillStyle,
	    resultTableFillStyle: themeUiBackgroundFillStyle,
	    resultTableStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
	    resultTableTextFillStyle: themeTextFillStyle,
	    customTickGridStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeCursorGridlineFillStyle }),
	    uiPointableTextBoxFillStyle: themeUiBackgroundFillStyle,
	    uiPointableTextBoxStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
	    uiPointableTextBoxTextFillStyle: themeTextFillStyle,
	    pointMarkerFillStyle: new SolidFill({ color: ColorCSS('#ffffff') }),
	    chartXYZoomingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
	    chartXYZoomingRectangleStrokeStyle: new SolidLine({ thickness: 1, fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }) }),
	    chartXYFittingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
	    chartXYFittingRectangleStrokeStyle: new SolidLine({ thickness: 1, fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }) }),
	  });



	  var license = `License key for domains *.tasq.io tasq.io

0001-ff5ad4e054f80f2dd0977e5194ea2c7804dcae2d8fc19d352bb53ce081d1e1c8cc21be14a8b691660e1133da1d4d73813c2b4d760ddc385f32cd859fc3f6b7ed664894c62e4fa717fea588bb829429c8f7f9218a4f1f6684ca663999e246-2acb5316c96ce6435be3182ea4ee94db-30450220319f968bebc89be5d0ac8cb5b1903e4e023a07525c1b84d8aed5136bb0667a82022100f3ad7a24bde5f7427f10f3c1367aff4708fad5507d937da7e75220d5e29578d9
`
	  this.chart = lightningChart({ license: license }).ChartXY({ container: `${this.chartId}`, theme: myTheme }).setTitle('');
	  // .setPadding({ top: 50 })
	  this.chart.setAnimationsEnabled(false);
	  this.chart.setMouseInteractionWheelZoom(false);

	  setTimeout(() => {
	    this.$nextTick(() => {
	      this.didSelectResetChart();
	    });
	  }, 2000);
	}

	async mounted() {
	  // Chart can only be created when the component has mounted the DOM because
	  // the chart needs the element with specified containerId to exist in the DOM
	  this.createChart();

	  const fromTime = new Date();
	  fromTime.setDate(fromTime.getDate() - tasqsListModule.signalChartTime);

	  this.dateOrigin = fromTime;
	  // Modify the default X Axis to use DateTime TickStrategy, and set the origin for the DateTime Axis.
	  this.chart.getDefaultAxisX().setTickStrategy(AxisTickStrategies.DateTime, (tickStrategy) => tickStrategy.setDateOrigin(this.dateOrigin));
	  // const legendProd = this.chart.addLegendBox()
	  // Dispose example UI elements automatically if they take too much space. This is to avoid bad UI on mobile / etc. devices.
	  // .setAutoDispose({
	  // 	type: 'max-width',
	  // 	maxWidth: 0.30,
	  // })
	  this.axisY2 = this.chart.addAxisY({
	    opposite: true,
	  });
	  this.axisY = this.chart.getDefaultAxisY()
	  // Hide tick grid-lines from second Y axis.
	    .setTickStrategy(AxisTickStrategies.Numeric, (ticks) => ticks
	      .setMinorTickStyle((minor) => minor
	        .setGridStrokeStyle(emptyLine))
	      .setMajorTickStyle((major) => major
	        .setGridStrokeStyle(emptyLine)));
	  if (getConfigEnv('PRODUCTION_TYPE') == 'gas') {
	    this.setupForGasTargets();
	  } else {
	    this.setupForOilTargets();
	  }
	  

	  setTimeout(() => {
	    this.$nextTick(() => {
	      this.didSelectResetChart();
	    });
	  }, 2000);


	  if (!this.isHistorySectionOpen) {
	  this.localHistoryItems = this.getWellHistoryEvents
	  this.addHistoryToChart(this.showWellEvents)
	  }else{
		tasqProductionDataChartModule.setShowWellEvents(false)
		await sleep(100)
		tasqProductionDataChartModule.setShowWellEvents(true)
	  }
	  if (this.maxY != undefined && this.maxY != -99999999) {
			this.axisY.setInterval(this.minY, this.maxY, false, true)
		}
	  setTimeout(() => {
		if (this.maxY != undefined && this.maxY != -99999999) {
			this.axisY.setInterval(this.minY, this.maxY, false, true)
		}
	  }, 1000);


	}

	beforeMount() {
	  // Generate random ID to us as the containerId for the chart and the target div id
	  this.chartId = Math.trunc(Math.random() * 1000000);
	}

	beforeUnmount() {
	  // "dispose" should be called when the component is unmounted to free all the resources used by the chart
	  this.chart.dispose();
	}
	
}
